import { useEffect, useState } from 'react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import Spinner from '../components/Shared/Spinner';
const HomePage: NextPage = () => {
  const router = useRouter();
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (window.location.pathname !== '/') {
      router.push(`${window.location.pathname}${window.location.search}`);
    } else {
      setShow(true);
    }
  }, [setShow, router]);
  if (!show) {
    return null;
  }

  return (
    <div className="grid place-items-center h-60">
      <Spinner className="text-primary" />
    </div>
  );
};

export default HomePage;
